@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import url('https://fonts.googleapis.com/css2?family=Readex Pro:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:wght@400&family=Readex Pro:wght@300;400;600&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Readex+Pro:wght@200;300;400;500;600;700&display=swap');

:root {
  --font-fam-dm: "Chivo", sans-serif;
  --font-fam-out: "Readex Pro", sans-serif;
  --font-size-large: 54px;
  --font-size-med: 20px;
  --font-size-small: 16px;
  --color-golden: #f9d278;
  --color-bg-element: #20221d;
}
*:focus{
  outline:none;
  transition: all 0.3s ease !important;
}
a{
  text-decoration: none !important;
  color: #fff;
}

.hidden{
  display: none;
}

.noScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main-heading{
  font-size: var(--font-size-large) !important;
  font-weight: 700 !important;
  color: #fff;
}
.sub-heading{
  font-size: var(--font-size-med) !important;
  font-weight: 400 !important;
}

body {
  margin: 0;
  font-family: --font-fam-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

body,html {
  width: 100% !important;
  height: 100%;
  position:relative;
  overflow-x:hidden !important;
  overflow-y: auto !important;
  scroll-behavior: smooth;
}

/* Track */
@supports (scrollbar-color:  rgba(20,23,13,1)  rgba(20,23,13,.99)) {
  html,
  body {
      scrollbar-width: thin !important;
      scrollbar-color:  rgba(20,23,13,1) transparent;
  }
}

html,
body {
  scroll-behavior: smooth;
  background-color:  rgba(20,23,13,.8) !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  
}

html::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-track {
  background: rgba(20,23,13,1);
  width: 5%;
}

html::-webkit-scrollbar-thumb {
  background: rgba(20,23,13,1);
  border-radius: 100vw;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
body::-webkit-scrollbar-track {
  background: rgba(20,23,13,1);
  width: 5%;
}
body::-webkit-scrollbar-thumb {
  background:rgba(20,23,13,1);
  border-radius: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn{
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-med);
  color: #000000;
  background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
  border-radius: 10px;
  border: none;
  cursor: pointer;
  padding: 0.75rem 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

button img{
  width: 1rem;
  height: 1rem;
}

.btn-outline {
  background-color: transparent;
  border: 1px solid rgba(250, 211, 122, 1);
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 2rem;
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-med);
  color: #ffffff;
  border-radius: 10px;
}
.btn-outline-icon {
  background-color: transparent;
  cursor: pointer;
  border: 1px solid rgba(250, 211, 122, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 45px;
  height: 45px;
  padding: 0 !important;
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-med);
  color: #ffffff;
  border-radius: 10px;
}
.btn-outline-icon img{
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
}
button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
@media screen and (max-width: 768px) {
  :root {
    --font-size-large: 28px;
    --font-size-med: 20px;
    --font-size-small: 16px;
  }
}

.cursor-pointer:hover,
button {
  cursor: pointer !important;
}