.accordion {
  background: #20221D;
  border-radius: 8px;
  margin: .5rem 0;
  min-width: 38rem;
  max-width: 40rem;
}


.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 141.8%;
  color: #FFFFFF;
}

.accordion-title:hover {
  background: #20221D;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background: #20221D;
  font-family: 'Readex Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 141.8%;
  color: #FFFFFF;
  opacity: 0.8;
  
}

@media screen and (max-width: 1150px) {
.accordion {
  background: #20221D;
  border-radius: 8px;
  margin: .5rem 0;
  min-width: 27rem;
  max-width: 27rem;
}
}


@media screen and (max-width: 850px) {
.accordion {
  background: #20221D;
  border-radius: 8px;
  margin: .5rem 0;
  min-width: 100%;
  max-width: 100%;
}
}


@media screen and (max-width: 395px) {
.accordion {
  background: #20221D;
  border-radius: 8px;
  margin: .5rem 0;
  min-width: 100%;
  max-width: 100%;
  
}

.accordion-content,.accordion-title{
  font-size: .8rem;
}
}