.follow-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: .25rem;
    border-radius: 8px;
    border: 1px solid rgba(250, 211, 122, 1);
    margin-right: .5rem;
    cursor: pointer;
}