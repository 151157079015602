.stake-box-container{
    width: 25rem;
    height: 18rem;
    background: #20221D;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: space-between;
    
}

.stake-box-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 141.8%;
    color: #FFFFFF;
}

.container2{
    width: 24rem;
    height: 8rem;
    background: #14170D;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    justify-content: space-between;
}

.container2-heading1, .container2-heading2{
    display: flex;
    flex-direction: column;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: .8rem;
    line-height: 141.8%;
    color: #FFFFFF;

}

.b44{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: .2rem;
}


.container2-heading1 input{
    background-color: transparent;
    border: 1px solid rgba(132, 132, 132, 0.4);
    border-radius: 4px;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 141.8%;
    color: #FFFFFF;
    margin-top: .2rem;
    padding: .4rem .6rem;
}
.b44 > div:hover {
    cursor: pointer;
}
.classic,.silver,.gold,.diamond{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    line-height: 141.8%;
    color: #FFFFFF;
    width: 5.7rem;
    text-align: center;
    background-color: transparent;
    border-radius: .25rem;
    border: none;
}

.classic{
    border: 1px solid rgba(116, 86, 59, 1);
}

.silver{
    border: 1px solid rgba(121, 121, 120, 1);
}

.gold{
    border: 1px solid rgba(242, 216, 148, 1);  
}

.diamond{
    border: 1px solid rgba(250, 250, 250, 1);  
}

.stake-box-rewards{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: .8rem;
    line-height: 141.8%;
    color: #FFFFFF;
}

.stake-token{
    background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 16.83%, #FFE099 67.68%, #B8933D 100%);
    box-shadow: 0px 1px 15px rgba(252, 217, 135, 0.4);
    border-radius: 8px;
    border: none;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 141.8%;
    text-align: center;
    color: #14170D;
    padding: .65rem;
}

@media screen and (max-width:1150px){

    .stake-box-container{
        width: 20rem;
        height: 18rem;
        background: #20221D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        justify-content: space-between;
        
    }

    .container2{
        width: 19rem;
        height: 8rem;
        background: #14170D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: .5rem;
        justify-content: space-between;
    }

    .classic,.silver,.gold,.diamond{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    line-height: 141.8%;
    color: #FFFFFF;
    width: 4.3rem;
    text-align: center;
    background-color: transparent;
    border-radius: .25rem;
    border: none;
}

    .classic{
        border: 1px solid rgba(116, 86, 59, 1);
    }
    
    .silver{
        border: 1px solid rgba(121, 121, 120, 1);
    }
    
    .gold{
        border: 1px solid rgba(242, 216, 148, 1);  
    }
    
    .diamond{
        border: 1px solid rgba(250, 250, 250, 1);  
    }

    .b44{
        display: flex;
        flex-direction: row;
        margin-top: .2rem;
    }
}

@media screen and (max-width:850px){

    .stake-box-container{
        width: 93%;
        height: 18rem;
        background: #20221D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        justify-content: space-between;    
    }

    .container2{
        width: 98%;
        height: 8rem;
        background: #14170D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: .5rem;
        justify-content: space-between;
    }

    .classic,.silver,.gold,.diamond{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 400;
        font-size: .8rem;
        line-height: 141.8%;
        color: #FFFFFF;
        width: 100%;
        text-align: center;
        background-color: transparent;
        border-radius: .25rem;
        border: none;
    }

    .b-classic,.b-silver,.b-gold,.b-diamond{
        width: 100%;
    }
    .b-classic,.b-silver,.b-gold{
        margin: 0 1rem 0 0;
    }
    .classic{
        border: 1px solid rgba(116, 86, 59, 1);
    }
    
    .silver{
        border: 1px solid rgba(121, 121, 120, 1);
    }
    
    .gold{
        border: 1px solid rgba(242, 216, 148, 1);  
    }
    
    .diamond{
        border: 1px solid rgba(250, 250, 250, 1);  
    }

    .b44{
        display: flex;
        flex-direction: row;
        margin-top: .2rem;
    }
}

@media screen and (max-width:500px){

    .stake-box-container{
        width: 92%;
        height: 18rem;
        
    }

    .container2{
        width: 95%;
        height: 8rem;
    }

    .classic,.silver,.gold,.diamond{
        font-size: .75rem;
        line-height: 141.8%;
        color: #FFFFFF;
        text-align: center;
        background-color: transparent;
        border-radius: .25rem;
        border: none;
    }

    .classic{
        border: 1px solid rgba(116, 86, 59, 1);
    }
    
    .silver{
        border: 1px solid rgba(121, 121, 120, 1);
    }
    
    .gold{
        border: 1px solid rgba(242, 216, 148, 1);  
    }
    
    .diamond{
        border: 1px solid rgba(250, 250, 250, 1);  
    }

    .b44{
        display: flex;
        flex-direction: row;
        margin-top: .2rem;
    }
}

@media screen and (max-width:395px){

    .stake-box-container{
        width: 90%;
        
    }

    .container2{
        width: 95%;
    }

    .classic,.silver,.gold,.diamond{
        font-size: .65rem;
    }

    .classic{
        border: 1px solid rgba(116, 86, 59, 1);
    }
    
    .silver{
        border: 1px solid rgba(121, 121, 120, 1);
    }
    
    .gold{
        border: 1px solid rgba(242, 216, 148, 1);  
    }
    
    .diamond{
        border: 1px solid rgba(250, 250, 250, 1);  
    }

    .b44{
        display: flex;
        flex-direction: row;
        margin-top: .2rem;
    }
}

@media screen and (max-width:320px) {
    .stake-box-container{
        width: 87%;
    }

    .classic,.silver,.gold,.diamond{
        font-size: .45rem;
    }
}
.cursor-pointer:hover {
    cursor: pointer !important;
  }