.stake-container{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(20,23,13,.99);
    font-family: 'Readex Pro';
    justify-content: space-between;
    position: relative;
}

.display-content{
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 2fr auto;
    margin: 3rem 5.5rem 5rem 5.5rem;
    z-index: 2;
}


.display-content-left,.display-content-right{
    display: flex;
    flex-direction: column;
}


.display-content-left-heading1,
.display-content-left-heading2,
.display-content-left-heading3,
.display-content-left-heading4,
.display-content-right-heading1,
.display-content-right-heading2{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.65rem;
    line-height: 141.8%;
    color: #FFFFFF;
    margin-bottom: .75rem;
}

.display-content-left-container1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: .75rem;
}

.display-content-left-container2,
.display-content-left-container3{
    margin-bottom: 5.5rem;
}

.join-button{
    padding: .5rem 1.5rem;
    background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
    border-radius: 8px;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 141.8%;
    text-align: center;
    color: #14170D;
    border: none;
}

.b56{
    display: flex;
    flex-direction: row;
    background: #20221D;
    border-radius: 4px;
    justify-content: space-between;
    padding: .15rem;
    font-family: 'Readex Pro';
    margin-bottom: .75rem;

}

.b76{
    width: 50%;
    background-color: transparent;
    border-radius: 4px;
    outline: none;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: #FFFFFF;
    padding: .25rem;
}


.b77{
    width: 50%;
    background: #14170D;
    border-radius: 4px;
    outline: none;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: #FFFFFF;
    padding: .25rem;
}

.display-content-right-container1{
    margin-bottom: 5.5rem;
}

@media screen and (max-width:1150px){
    .display-content-left-container1{
        display: flex;
        flex-direction: column;
    }

    .display-content-right,.display-content-left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .display-content-right{
        margin-bottom: 5.5rem;
    }
    .display-content-left-heading4{
        width: 25rem;
    }

}

@media screen and (max-width:850px){
    .display-content{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 90%;
    }

    .display-content-left-container1{
        display: flex;
        flex-direction: column;
    }

    .display-content-left-container1,.display-content-left-container2,.display-content-left-container3{
        width: 100%;
    }

    .display-content-right,.display-content-left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .display-content-right-container1,.display-content-right-container2{
        width: 100%;
    }

    .stake-unstake{
        width: 100%;
    }

    .b56{
        width: 97.25%;
    }

    .display-content-right{
        margin-bottom: 5.5rem;
    }
    .display-content-left-heading4{
        width: 100%;
    }

}

@media screen and (max-width:500px){
    .display-content{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .display-content-left-container1{
        display: flex;
        flex-direction: column;
    }

    .display-content-right,.display-content-left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .display-content-right{
        margin-bottom: 5.5rem;
    }

}

@media screen and (max-width:395px){
    .display-content{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .display-content-left-container1{
        display: flex;
        flex-direction: column;
    }

    .display-content-right,.display-content-left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .display-content-right{
        margin-bottom: 5.5rem;
    }

    .display-content-left-heading1,
    .display-content-left-heading2,
    .display-content-left-heading3,
    .display-content-left-heading4,
    .display-content-right-heading1,
    .display-content-right-heading2{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 141.8%;
        color: #FFFFFF;
        margin-bottom: .75rem;
    }

    .join-button{
        padding: .4rem 1.4rem;
        background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
        border-radius: 8px;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 600;
        font-size: .8rem;
        line-height: 141.8%;
        text-align: center;
        color: #14170D;
        border: none;
    }

}