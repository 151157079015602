.mint-container{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(20,23,13,.99);
    font-family: 'Readex Pro';
    justify-content: space-between;
    position: relative;
}

.mint-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.mint-top{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 4rem 0 6rem 0;
    gap: 3rem;
    align-items: center;
}

.mint-middle{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.mint-middle-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 141.8%;
    color: #FFFFFF;
}

.mint-middle-perks{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
    margin-top: 5rem;
}

.mint-bottom{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 6rem;
    margin-bottom: 8rem;
}

.mint-bottom-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 141.8%;
    color: #FFFFFF;
}


@media screen and (max-width:768px){
    .mint-content{
        width: 80%;
    }
    .mint-top,.mint-middle-perks,
    .mint-middle,
    .mint-bottom{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .mint-middle,.mint-bottom{
        align-items: flex-start;
    }

    .mint-middle-heading{
        font-size: 30px;
    }

    .mint-bottom-heading{
        margin-bottom: 1rem;
        font-size: 30px;
    }
    .mint-middle-perks{
        align-items: center;
        gap:5rem;
    }

    .mint-bottom-faq{
        width: 100%;
    }

}