.expert-mode-container{
    background: #252524;
    border-radius: 12px;
    height: auto;
    width: 16rem;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    color: white;
}

.expert-mode-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 141.8%;
    color: #FFFFFF;
}

.expert-mode-content1{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 350;
    font-size: .7rem;
    line-height: 141.8%;
    color: #FFFFFF;
}

.b2{
    background:linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
    border: none;
    outline: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: .7rem;
    color: black;
    font-family: 'Readex Pro';
    font-style: normal;
    text-align: center;
    height:2.3rem;
    width:100%;
    cursor: pointer;
}

@media screen and (min-width:769px) {
    .expert-mode-container{
        width: 18rem;
    }

    .expert-mode-heading{
        font-size: 1.75rem;
    }

    .expert-mode-content1{
        font-size: 1rem;
    }

    .b2{
        font-size: 1rem;
        height: 2.5rem;
    }
}

@media screen and (max-width:320px) {
    .expert-mode-container{
        width:14rem;
        height: auto;
    }
}