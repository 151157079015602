.close{
    position: absolute;
    left: 87%;
    top: 10%;
    cursor: pointer;
}

.close-button{
    margin: 0;
}
.transaction-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.setting-container{
    /* box-sizing: border-box; */
    background: #252524;
    border-radius: 12px;
    height: auto;
    width: 18rem;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    color: white;
}

.conatiner-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 141.8%;
    color: #FFFFFF;
    margin-bottom: 1.5rem;
}

.slippage-tolerance{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.25rem;
}

.slippage-tolerance-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 141.8%;
}

.slippage-tolerance-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;
    width: 100%;
}

.b-auto{
    flex: .35;
    margin-right: .25rem;
}

.b12{
    background:linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
    border: none;
    outline: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    font-family: 'Readex Pro';
    font-style: normal;
    text-align: center;
    height: 2rem;
    padding: 0 1rem;
    width: 100%;
    cursor: pointer;
}

.text{
    border: 1px solid rgba(132, 132, 132, 0.2);
    border-radius: 4px;
    height: 1.75rem;
    flex: 1;
    width: 100%;
}

.text input{
    
    margin-left: 0;
    outline: none;
    background-color: transparent;
    border: none;
    height: 1.75rem;
    width: 100%;
}

.transaction-deadline{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 1.5rem;
}

.transaction-deadline-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 141.8%;
}

.transaction-input{
    border: 1px solid rgba(132, 132, 132, 0.2);
    border-radius: 4px;
    height: 1.75rem;
    margin-top: .75rem;
    
}

.transaction-input input{
    outline: none;
    background-color: transparent;
    border: none;
    height: 1.75rem;
    width: 100%;
}

.expert-mode{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.expert-mode-left{
    font-family: 'Readex Pro';
    font-weight: 400;
    font: 1rem;
}

.expert-mode-right{
    margin-top: .75rem;
}

@media screen and (min-width:769px) {
    .setting-container{
        width: 20rem;
    }
    
    .conatiner-heading{
        font-size: 1.75rem;
    }

    .expert-mode-left,.slippage-tolerance-heading,.transaction-deadline-heading{
        font-size: 1.25rem;
    }

    .b12{
        font-size: 1.2rem;
    }
}


@media screen and (max-width:360px) {
    .setting-container{
        width: 14rem;
        height: auto;
    }

    .conatiner-heading{
        font-size: 1.25rem;
    }
}
