.total-container{
    /* position: absolute; */
    z-index: 2;
    width: 100%;
    height: calc(100vh - 5rem);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swap-container{
    box-sizing: border-box;
    width: 600px;
    height: auto;
    background: #20221D;
    border-radius: 24px;
    margin: auto 2rem;
    /* margin: 5rem 28rem; */
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
    z-index: 2;
}

.swap-middle{
    /* margin-bottom: 2rem; */
    position: relative;
    margin-top: 1.5rem;
}

.swap-middle-bottom,.swap-middle-top{
    width: 100%;
    position: relative;
}

.swap-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.swap-top-left{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 700;
    color:white;
    opacity: 0.9;
    line-height: 1.3;
    font-size: 2rem;
}

.swap-top-right{
    cursor: pointer;
}

.setting{
    height: 1.8rem;
    width: 1.8rem;
    /* margin-top: .25rem; */
}

.arrow-div{
    z-index: 5;
    margin: 0;
    position: relative;
    cursor: pointer;
}
.arrow-div:hover{

    filter: brightness(120%);
}

.arrow{
    width: 3rem;
    height: 3rem;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
}

.swap-middle-top{
    margin-top: 0.7rem;
    position: relative;
}
.showBalance {
    position: absolute;
    top: 8px;
    right: 42px;
    color:white;
    font-size: 11px;
    font-weight: 500;
}

.swap-middle-bottom{
    margin-top: .7rem;
}

.b11{
    background:linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
    border: none;
    outline: none;
    margin-top: 3rem;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.3rem;
    color: black;
    font-family: 'Readex Pro';
    font-style: normal;
    text-align: center;
    padding: 1rem;
    cursor:pointer;
}

@media screen and (max-width: 769px){
    .swap-container{
        padding: 1rem;
        margin: 1rem;
    }
    .swap-top-left{
        font-size: 1.75rem;
    }
    .setting{
        height: 1.6rem;
        width: 1.6rem;
    }
    .swap-middle{
        margin-top: .5rem;
    }
    .b11{
        margin-top: 1.5rem;
    }
}

/* @media screen and (max-width:2560px) {
    .swap-container{
        padding: 2rem 1.75rem;
    }
    .swap-top-left{
        font-size:5rem;
    }

    .setting{
        height: 4.5rem;
        width: 4.5rem;
    }

    .b11{
        font-size: 2.5rem;
        padding: 1.75rem;
    }

    .arrow{
        height: 4.5rem;
        width: 4.5rem;
    }
    
    .arrow-div{
        transform: translate(0,600%);
    }
} */

/* @media screen and (max-width:1960px) {
    .swap-top-left{
        font-size: 3.5rem;
    }

    .setting{
        height: 3rem;
        width: 3rem;
    }

    .b11{
        font-size: 2.2rem;
        padding: 1.65rem;
    }

    .arrow-div{
        transform: translate(0,510%);
    }
}


@media screen and (max-width:1440px){
    .swap-top-left{
        font-size: 2.25rem;
    }

    .swap-container{
        padding: 1.5rem 1.25rem;
    }

    .b11{
        font-size: 1rem;
        padding: 1rem;
    }

    .setting{
        height: 2rem;
        width: 2rem;
        margin-top: .25rem;
    }

    .arrow{
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 0;
    }

    .arrow-div{
        transform: translate(0%,640%);
    }
}


@media screen and (max-width:1120px) {
    .swap-top-left{
        font-size: 2.1rem;
    }

    .setting{
        height: 1.9rem;
        width: 1.9rem;
        margin-top: .25rem;
    }

    .arrow{
        height:2.25rem;
        width:2.25rem;
    }
    .arrow-div{
        transform: translate(0%,670%);
    }
}

@media screen and (max-width:960px) {
    .swap-top-left{
        font-size: 2rem;
    }

    .setting{
        height: 1.75rem;
        width: 1.75rem;
        margin-top: .25rem;
    }

    .arrow{
        height:2.15rem;
        width:2.15rem;
    }
    .arrow-div{
        transform: translate(0%,690%);
    }
}

@media screen and (max-width:850px) {

    .total-container{
        width: 50%;
    }
    .swap-top-left{
        font-size: 1.8rem;
    }

    .arrow{
        height:2rem;
        width:2rem;
    }

    .setting{
        height: 1.65rem;
        width: 1.65rem;
        margin-top: .25rem;
    }

}

@media screen and (max-width:700px) {
    .total-container{
        width: 60%;
    }

}

@media screen and (max-width:500px){
    .total-container{
        width: 85%;
    }
    .stake-container{
        width: 100%;
        height: auto;
        background: #20221D;
        border-radius: 28px;
        margin: 5rem 28rem;
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }

    .swap-middle{
        width: 100%;
    }

    .swap-middle-top,.swap-middle-bottom{
        width: 100%;
    }

    .arrow{
        width: 1.755rem;
        height: 1.755rem;
        margin-bottom: 0;
    }

    .arrow-div{
        transform: translate(0%,785%);
    }

}


@media screen and (max-width:360px){
    .stake-container{
        width: 100%;
        height: auto;
        background: #20221D;
        border-radius: 28px;
        margin: 5rem 28rem;
        display: flex;
        flex-direction: column;
    }


    .arrow-div{
        transform: translate(0%,775%);
    }
}

@media screen and (max-width:320px){
    .total-container{
        width: 85%;
    }

    .arrow{
        width: 1.65rem;
        height: 1.65rem;
        margin-bottom: 0;
    }

    .arrow-div{
        transform: translate(0%,810%);
    }

} */
