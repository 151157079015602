.perks-container{
    display: flex;
    flex-direction: row;
    padding: 1rem;
    background: #20221D;
    border-radius: 8px;
    width: 38rem;
    height: 3.5rem;
    margin: 1rem 0;
}
.perk-image{
    flex: .15;
}

.perk-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.perk-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 141.8%;
    color: #FFFFFF;
    margin-bottom: .75rem;
}

.perk-subheading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 300;
    font-size: .8rem;
    line-height: 141.8%;
    color: #FFFFFF;
    opacity: 0.8;
}

.p1{
    height: 4rem;
    width: 4rem;
}

@media screen and (max-width:1150px){
    .perks-container{
        display: flex;
        flex-direction: row;
        padding: 1rem;
        background: #20221D;
        border-radius: 8px;
        width: 25rem;
        height: 3.5rem;
        margin: 1rem 0;
    }

    .perk-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 600;
        font-size: .85rem;
        line-height: 141.8%;
        color: #FFFFFF;
        margin-bottom: .75rem;
    }

    .perk-subheading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 300;
        font-size: .65rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.8;
    }
}

/* @media screen and (max-width:810px){
    .perks-container{
        display: flex;
        flex-direction: row;
        padding: 1rem;
        background: #20221D;
        border-radius: 8px;
        width: 20rem;
        height: 3.5rem;
        margin: 1rem 0;
    }

    .perk-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 600;
        font-size: .85rem;
        line-height: 141.8%;
        color: #FFFFFF;
        margin-bottom: .75rem;
    }

    .perk-subheading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 300;
        font-size: .65rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.8;
    }
} */

@media screen and (max-width:850px){
    .perks-container{
        display: flex;
        flex-direction: row;
        padding: 1rem;
        background: #20221D;
        border-radius: 8px;
        width: 94%;
        height: 3.5rem;
        margin: 1rem 0;
    }

    .perk-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 600;
        font-size: .85rem;
        line-height: 141.8%;
        color: #FFFFFF;
        margin-bottom: .75rem;
    }

    .perk-subheading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 300;
        font-size: .65rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.8;
    }
}

@media screen and (max-width:500px){
    .perks-container{
        width: 93%;
    }

    .perk-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 600;
        font-size: .75rem;
        line-height: 141.8%;
        color: #FFFFFF;
        margin-bottom: .75rem;
    }

    .perk-subheading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 300;
        font-size: .65rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.8;
    }
}

@media screen and (max-width:395px){
    .perks-container{
        display: flex;
        flex-direction: row;
        padding: 1rem;
        background: #20221D;
        border-radius: 8px;
        width: 90%;
        height: 3.5rem;
        margin: 1rem 0;
    }

    .perk-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 600;
        font-size: .65rem;
        line-height: 141.8%;
        color: #FFFFFF;
        margin-bottom: .75rem;
    }

    .perk-subheading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 300;
        font-size: .55rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.8;
    }
}

@media screen and (max-width:320px) {
    .perks-container{
        width: 87%;
    }
}