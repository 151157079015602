.token-info {
  margin: 0 auto;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: 95%;
  max-width: 1290px;
  color: #fff;
}

.token-info-heading {
  font-size: var(--font-size-large);
  font-family: var(--font-fam-dm);
  font-weight: 400 !important;
  text-align: center;
  line-height: 1;
}

.token-info-navigation {
  position: relative;
  padding-bottom: 1rem;
  display: flex;
  width: 90%;
  height: 60px;
  justify-content: space-between;
  align-items: flex-end;
  font-family: var(--font-fam-out);
}

.token-info-navigation::after {
  content: "";
  position: absolute;
  border-radius: 16px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background-color: var(--color-golden);
  opacity: 0.2;
}

.token-info-navigation-option {
  position: relative;
  width: 25%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  font-size: var(--font-size-med);
  font-weight: 400 !important;
  cursor: pointer;
}

.token-info-navigation-option a {
  white-space: nowrap;
}

.token-info-navigation-option::after {
  content: "";
  position: absolute;
  border-radius: 16px;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--color-golden);
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.token-info-navigation-option.current::after {
  opacity: 1;
}

.hyper-link-logo {
  vertical-align: middle;
  margin: 0 20px;
  display: inline-block;
  height: 100%;
}

.token-info-elements {
  font-family: var(--font-fam-out);
  font-weight: 600 !important;
  width: 60%;
  min-width: 460px;
}


.token-info-elements .token-info-element-hide-bg {
  background-color: transparent;
}

.token-info-element {
  position: relative;
  padding: 40px;
  padding-top: 120px;
  background-color: var(--color-bg-element);
  border-radius: 20px;
}

.token-info-details {
  max-width: 800px;
  font-size: 20px;
  font-weight: 400;
  margin: 0 1.5rem;
  font-family: 'Readex Pro';
  text-align: center;
}

.token-info-element-image {
  position: absolute;
  top: -100px;
  left: 50%;
  width: 200px;
  height: 200px;
  object-fit: contain;
  transform: translateX(-50%);
}

.token-info-element-heading {
  text-align: center;
  font-size: var(--font-size-med);
}

.token-info-element-info {
  margin-top: 17px;
  font-size: var(--font-size-med);
  font-weight: 400 !important;
  opacity: 0.8;
}

.token-info-view-chart {
  padding: 16px;
  font-family: var(--font-fam-out);
  font-size: var(--font-size-small);
  font-weight: 600;
  border: 2px solid var(--color-golden);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
}

.token-info-supply {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 192px;
}

.supply-pie-chart {
  display: block;
  max-width: 600px;
  width: 100%;
  height: 100%;
}

.token-info-supply-stats {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.token-info-supply-element {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  font-weight: 400 !important;
  font-family: var(--font-fam-out);
}

.token-info-supply-element::before {
  position: absolute;
  height: 100%;
  width: 6px;
  content: "";
  left: -20px;
  border-radius: 20px;
}

.token-info-supply-element:nth-of-type(1)::before {
  background-color: #9a7d59;
}

.token-info-supply-element:nth-of-type(2)::before {
  background-color: #292933;
}

.token-info-supply-element:nth-of-type(3)::before {
  background-color: #474755;
}

.token-info-supply-name {
  font-size: var(--font-size-small);
  opacity: 0.8;
}

.token-info-supply-percent {
  font-size: var(--font-size-med);
}

.token-info-supply-button {
  display: block;
  padding: 20px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 1px solid var(--color-golden);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50%;
}

.token-info-supply-button>img {
  display: block;
  height: 100%;
  width: auto;
}

.token-info-view-chart>.hyper-link-logo {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .token-info {
    gap: 2rem;
  }

  .token-info-navigation-option {
    /* font-size: var(--font-size-small); */
    width: fit-content;
  }

  .token-info-navigation {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .token-info-elements {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    margin-top: 100px;
    align-items: center;
    gap: 120px;
  }

  .token-info-elements .token-info-element-hide-bg {
    background-color: transparent;
  }


  .token-info-supply {
    flex-direction: column;
    gap: 80px;
  }

  .background-particle {
    position: absolute;
    top: 50%;
  }
}