.unstake-box-container{
    width: 25rem;
    height: 24rem;
    background: #20221D;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: space-between;
    
}

.unstake-box-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 141.8%;
    color: #FFFFFF;
}

.unstake-container2{
    width: 24rem;
    height: 7rem;
    background: #14170D;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    margin-top: .25rem;
    justify-content: space-between;
}

.unstake-container2-heading1, .unstake-container2-heading2{
    display: flex;
    flex-direction: column;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: .8rem;
    line-height: 141.8%;
    color: #FFFFFF;

}

.unstake-b44{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    margin-top: .2rem;
}


.unstake-container2-heading1 input{
    background-color: transparent;
    border: 1px solid rgba(132, 132, 132, 0.4);
    border-radius: 4px;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 141.8%;
    color: #FFFFFF;
    margin-top: .2rem;
    padding: .4rem .6rem;
}

.i25,.i50,.i75,.i100{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    line-height: 141.8%;
    text-align: center;
    color: #FFFFFF;
    width: 5.7rem;
    text-align: center;
    background-color: transparent;
    border-radius: .25rem;
    border: 1px solid rgba(132, 132, 132, 0.4);
}


.unstake-box-rewards{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: .8rem;
    line-height: 141.8%;
    color: #FFFFFF;
}

.unstake-token{
    background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 16.83%, #FFE099 67.68%, #B8933D 100%);
    box-shadow: 0px 1px 15px rgba(252, 217, 135, 0.4);
    border-radius: 8px;
    border: none;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 141.8%;
    text-align: center;
    color: #14170D;
    padding: .65rem;
    outline: none;
    width: 13rem;
}

.immediate{
    background: transparent;
    border-radius: 8px;
    outline: none;
    border: 1px solid 
    rgba(249, 210, 120, 1);
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 141.8%;
    text-align: center;
    color: rgba(249, 210, 120, 1);
    padding: .65rem;
}

.b45{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
}


.b-immediate,.b-unstake-token{
    width: 100%;
}


.unstake-content{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: .65rem;
    line-height: 141.8%;
    color: #FFFFFF;
    opacity: 0.8;
}

@media screen and (max-width:1150px){
    .unstake-box-container{
        width: 20rem;
        height: 24rem;
        background: #20221D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        justify-content: space-between;
        
    }

    .unstake-container2{
        width: 19rem;
        height: 8rem;
        background: #14170D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: .5rem;
        margin-top: .25rem;
        justify-content: space-between;
    }

    .i25,.i50,.i75,.i100{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 400;
        font-size: .75rem;
        line-height: 141.8%;
        text-align: center;
        color: #FFFFFF;
        width: 4.3rem;
        text-align: center;
        background-color: transparent;
        border-radius: .25rem;
        border: 1px solid rgba(132, 132, 132, 0.4);
    }

    .unstake-token{
        background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 16.83%, #FFE099 67.68%, #B8933D 100%);
        box-shadow: 0px 1px 15px rgba(252, 217, 135, 0.4);
        border-radius: 8px;
        border: none;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .8rem;
        line-height: 141.8%;
        text-align: center;
        color: #14170D;
        padding: .65rem;
        outline: none;
        width: 10rem;
    }

    .immediate{
        background: transparent;
        border-radius: 8px;
        outline: none;
        border: 1px solid 
        rgba(249, 210, 120, 1);
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .75rem;
        line-height: 141.8%;
        text-align: center;
        color: rgba(249, 210, 120, 1);
        padding: .65rem;
    }
}

@media screen and (max-width:850px){
    .unstake-box-container{
        width: 93%;
        height: 24rem;
        background: #20221D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        justify-content: space-between;
        
    }

    .unstake-container2{
        width: 98%;
        height: 8rem;
        background: #14170D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: .5rem;
        margin-top: .25rem;
        justify-content: space-between;
    }

    .i25,.i50,.i75,.i100{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 400;
        font-size: .75rem;
        line-height: 141.8%;
        text-align: center;
        color: #FFFFFF;
        width: 100%;
        text-align: center;
        background-color: transparent;
        border-radius: .25rem;
        border: 1px solid rgba(132, 132, 132, 0.4);
    }

    .b81,.b82,.b83,.b84{
        width: 100%;
    }

    .b81,.b82,.b83{
        margin: 0 1rem 0 0;
    }

    .b-immediate{
        margin-right: 1rem;
    }

    .unstake-token{
        background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 16.83%, #FFE099 67.68%, #B8933D 100%);
        box-shadow: 0px 1px 15px rgba(252, 217, 135, 0.4);
        border-radius: 8px;
        border: none;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .8rem;
        line-height: 141.8%;
        text-align: center;
        color: #14170D;
        padding: .65rem;
        outline: none;
        width: 100%;
    }

    .immediate{
        background: transparent;
        border-radius: 8px;
        outline: none;
        border: 1px solid 
        rgba(249, 210, 120, 1);
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .75rem;
        line-height: 141.8%;
        text-align: center;
        color: rgba(249, 210, 120, 1);
        padding: .65rem;
        width: 100%;
    }
}

@media screen and (max-width:500px){
    .unstake-box-container{
        width: 92%;
        height: 24rem;
        background: #20221D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        justify-content: space-between;
        
    }

    .unstake-container2{
        width: 95%;
        height: 8rem;
        background: #14170D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: .5rem;
        margin-top: .25rem;
        justify-content: space-between;
    }

    .i25,.i50,.i75,.i100{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 400;
        font-size: .60rem;
        line-height: 141.8%;
        text-align: center;
        color: #FFFFFF;
        text-align: center;
        background-color: transparent;
        border-radius: .25rem;
        border: 1px solid rgba(132, 132, 132, 0.4);
    }

    .b81,.b82,.b83{
        width: 100%;
    }
    .b84{
        width: 100%;
    }

    .unstake-token{
        background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 16.83%, #FFE099 67.68%, #B8933D 100%);
        box-shadow: 0px 1px 15px rgba(252, 217, 135, 0.4);
        border-radius: 8px;
        border: none;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .8rem;
        line-height: 141.8%;
        text-align: center;
        color: #14170D;
        padding: .70rem 2rem;
        outline: none;
        width: 100%;
    }

    .immediate{
        background: transparent;
        border-radius: 8px;
        outline: none;
        border: 1px solid 
        rgba(249, 210, 120, 1);
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .60rem;
        line-height: 141.8%;
        text-align: center;
        color: rgba(249, 210, 120, 1);
        padding: .75rem;
        
    }

    
}

@media screen and (max-width:395px){
    .unstake-box-container{
        width: 90%;
        height: 24rem;
        background: #20221D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        justify-content: space-between;
        
    }

    .unstake-container2{
        width: 95%;
        height: 8rem;
        background: #14170D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: .5rem;
        margin-top: .25rem;
        justify-content: space-between;
    }

    .i25,.i50,.i75,.i100{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 400;
        font-size: .60rem;
        line-height: 141.8%;
        text-align: center;
        color: #FFFFFF;
        text-align: center;
        background-color: transparent;
        border-radius: .25rem;
        border: 1px solid rgba(132, 132, 132, 0.4);
    }

    .b81,.b82,.b83{
        width: 100%;
    }
    .b84{
        width: 100%;
    }

    .unstake-token{
        background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 16.83%, #FFE099 67.68%, #B8933D 100%);
        box-shadow: 0px 1px 15px rgba(252, 217, 135, 0.4);
        border-radius: 8px;
        border: none;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .8rem;
        line-height: 141.8%;
        text-align: center;
        color: #14170D;
        padding: .70rem 2rem;
        outline: none;
        width: 100%;
    }

    .immediate{
        background: transparent;
        border-radius: 8px;
        outline: none;
        border: 1px solid 
        rgba(249, 210, 120, 1);
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .60rem;
        line-height: 141.8%;
        text-align: center;
        color: rgba(249, 210, 120, 1);
        padding: .65rem;
        
    }

    
}

@media screen and (max-width:320px) {
    .unstake-box-container{
        width: 87%;
        height: 26rem;
    }

    .unstake-token{
        font-size: .60rem;
        padding: .70rem 1.5rem;
    }

    .immediate{
        font-size: .50rem;
        padding: .65rem .55rem;
        
    }
}