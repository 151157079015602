.footer-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #242424;
    height: 200px;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}
.footer-container a{
    font-size: 14px;
}

.footer-container-top{
    width: 80%;
    display: grid;
    grid-template-columns: .5fr .5fr .5fr .75fr 1fr;
}

.footer-logo{
    width: 82px !important;
    height: 4rem;
}
.foot-files{
    display: flex;
    flex-direction: column;
    font-family: 'Readex Pro';
    font-weight: 400;
    font-size: 14px;
    color: #FDFDFD !important;
    opacity: 0.8;
    gap: 0.5rem;
}

h1{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 35px;
    text-transform: capitalize;
    color: #FDFDFD;
    margin-top: 0;
}

h3{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-transform: capitalize;
    color: #FDFDFD;
    opacity: 0.8;
    margin: 0;
}

h2{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 141.8%;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

h4{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    text-transform: capitalize;
    color: #FDFDFD;
}

.footer-find-us{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    height: 50px;
}
.footer-stake{
    display: flex;
    flex-direction: column;
}
.footer-find-us-logos{
    display: flex;
    flex-direction: row;
    align-self: center;
}

.footer-container-copyright{
    margin-top: 2rem;
    grid-column-start: 2;
    grid-column-end: none;
}

@media screen and (max-width:768px) {
    .footer-container{
        height: auto;
        padding: 2rem 1rem;
    }
    
    .footer-container-top{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 90%;
    }

    .footer-container-copyright{
        margin: 0;
    }
}