.swiper.mySwiper-mint-page {
    width: 100%;
    height: fit-content;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 200px;
    height: 300px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
  }

  .swiper-pagination{
    bottom: 0 !important;
  }

  .swiper-pagination-bullet{
    opacity: 0.5 !important;
    background: #F9D278 !important;
  }

  .swiper-pagination-bullet-active{
    background-color:#F9D278 !important;
    opacity: 1 !important;
  }

  @media screen and (max-width:540px) {
      .swiper-slide{
          height: 215px !important;
      }
  }

  @media screen and (max-width:400px) {
      .swiper-slide{
          height: 160px !important;
      }
  }