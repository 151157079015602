.nft-container{
    background: linear-gradient(93.69deg, rgba(97, 83, 48, 0.24) -0.29%, rgba(97, 83, 48, 0.12) 100%);
    backdrop-filter: blur(100px);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.left-card{
    height: 18vh;
    width: 8vw;
    transform: translateY(-15px);
    margin: 0;
}

.right-card{
    height: 18vh;
    width: 8vw;
    margin: 0;
    transform: translateY(-15px);
}

.countdown{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.countdown-dropped{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}

.countdown-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 141.8%;
    text-align: center;
    color: #FFFFFF;
    margin-right: 3rem;
}

.timer{
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
}

.countdown-time{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 141.8%;
    text-align: center;
    color: #F9D278;
    margin: 0;
}

.time-left{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 300;
    font-size: .5rem;
    line-height: 141.8%;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.s1{
    margin-right: 2rem;
    margin-left: .75rem;
}

.s2{
    margin-right: 1.5rem;
}

.s3{
    margin-right: 1.5rem;
}

@media  screen and (max-width:650px) {
    .countdown{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .countdown-heading{
        margin-right: 0;
        font-size: 1rem;
    }

    .countdown-time{
        font-size: 1.5rem;
    }

    .time-left{
        font-size: .4rem;
    }

    .s1{
        padding-right: .5rem;
        padding-left: .75rem;
        margin-left: 0;
        margin-right: 0;
    }
    
    .s2{
        padding-right: .5rem;
        padding-left: .75rem;
        margin-left: 0;
        margin-right: 0;
    }
    
    .s3{
        padding-right: .5rem;
        padding-left: .5rem;
        margin-left: 0;
        margin-right: 0;
    }

    .s4{
        padding-left: .25rem;
    }
    
}

