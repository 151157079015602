.mint-perk-container{
    display: flex;
    flex-direction: column;
    background: #20221D;
    border-radius: 12px;
    padding: 1.5rem;
    width: 12rem;
    /* height: 10rem; */
    /* justify-content: space-between; */
    align-items: center;
    z-index: 5;
}

.mint-perk-image{
    min-height: 3rem;
    height: 3rem;
    width: 12rem;
    transform: translateY(-100px);
}
.mint-perk-image img{
    object-fit: cover;
    width: 172px;
    height: 142px;
}

.p4{
    height: 12rem;
    width: 12rem;
    position: absolute;
}

.mint-perk-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.mint-perk-content-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #FFFFFF;
}

.mint-perk-content-subheading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130.7%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    opacity: 0.8;
}

@media screen and (max-width:768px) {
    .mint-perk-container{
        width: 80%;
        /* height: 8rem; */
    }
}
