.merchandise {
  margin: 0 auto;
  width: 90%;
  max-width: 1290px;
  margin-bottom: 120px;
  position: relative;
  height: 100vh;
}

.swiper{
  height: 600px;
}

.merchandise-heading {
  font-size: var(--font-size-large);
  color: #fff;
  font-family: var(--font-fam-dm);
  font-weight: 400 !important;
  text-align: center;
  /* line-height: 1; */
  /* margin: 0; */
}
.merchandise-intro {
  margin: 0.5rem 0;
  color: #fff;
  font-size: var(--font-size-small);
  font-family: var(--font-fam-out);
  font-weight: 400;
  opacity: 0.8;
  text-align: center;
}
.merchandise-coming-soon {
  font-size: var(--font-size-small);
  font-family: var(--font-fam-out);
  font-weight: 400;
  width: 100%;
  color: var(--color-golden);
  margin-top: 32px;
  margin-bottom: 117px;
  text-align: center;
}
.merchandise-list {
  position: relative;
  margin: 0 auto;
  color: #fff;
  font-size: var(--font-size-small);
  font-family: var(--font-fam-out);
  filter: drop-shadow(0px -10px 140px #FFE09933);
}

.merchandise-image {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.merchandise-name,
.merchandise-price {
  text-align: center;
}
.background-ellipse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.swiper-slide {
  opacity: 0.5;
}
.swiper-slide-active {
  opacity: 1;
}
.merchandise-button {
  position: absolute;
  cursor: pointer;
  height: 50px;
  width: 50px;
  background-color: transparent;
  border: none !important;
  border-radius: 50%;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
}
.merchandise-button-icon-holder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.merchandise-button img {
  display: block;
  width: 2rem !important;
  height: 2rem !important;

}
.merchandise-button.left {
  left: 0;
}
.merchandise-button.right {
  right: 0;
}
.merchandise-button.right img{
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .merchandise {
    width: 80%;
  }
  .merchandise-intro {
    font-size: 20px;
  }
  .merchandise-heading {
    font-size: 40px;
  }
  .merchandise-coming-soon {
    font-size: 20px;
  }
}
