.wallet-error-container{
    background: #252524;
    border-radius: 12px;
    height: auto;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    color: white;
    align-items: center;
}

.error-heading{
    font-family: 'Readex Pro';
    font-weight: 600;
    font-size: 1.25rem;
}
.wallet-logo{
    height: 4.5rem;
    width: 4.5rem;
}

.error-logo{
   margin-top: 1.25rem;
}
.error-button{
    background:linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
    border: none;
    outline: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: .8rem;
    color: black;
    font-family: 'Readex Pro';
    font-style: normal;
    text-align: center;
    margin: 1rem 0;
    height:2.3rem;
    padding: 0 1rem;
    width: 100%;
    cursor: pointer;
}

@media screen and (min-width:769px) {
    .wallet-error-container{
        width: 440px;
        height: 230px;
    }
    
    .error-heading{
        font-size: 1.75rem;
    }

    .error-logo{
        height: 5.5rem;
        width: 5.5rem;
    }

    .wallet-logo{
        height: 5.5rem;
        width: 5.5rem;
    }
    .error-button{
        font-size: 1.2rem;
        height: 2.7rem;
    }
}

@media screen and (max-width:360px) {
    .wallet-error-container{
        width: 230px
    }

    .wallet-logo{
        height: 4rem;
        width: 4rem;
    }
    .error-heading{
        font-size: 1.15rem;
        width: 70%;
    }
}

