.header{
    width: 100%;
    height: 5rem;
    left: 0px;
    top: 0px;
    background: 
    rgba(20, 23, 13, 1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.logo{
    margin: 0 0 0rem 6.75rem;
}

.header-button{
    margin: 1.5rem 7.5rem 1.5rem 0;
}
.header-logo{
    height: 2.5rem !important;
    width: 140px !important;
    object-fit: cover !important;
}

.header-menu{
    background:linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
    border: none;
    outline: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    color: black;
    font-family: 'Readex Pro';
    font-style: normal;
    text-align: center;
    height:2.3rem;
    padding: 0 1rem;
    cursor: pointer;
}

@media screen and (max-width:1050px) {
    .logo{
        margin: .75rem 0 1rem 4.5rem;
    }
    
    .header-button{
        margin: 1.5rem 4.5rem 1.5rem 0;
    }
}

@media screen and (max-width:850px){

    .logo{
        margin: 0 0 0 1.75rem;
    }

    .header-button{
        margin: 1.5rem 2.5rem 1.5rem 0;
    }
}

@media screen and (max-width:500px){
    .header-menu{
        background:linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
        font-size: .75rem;
        height:2rem;
        padding: 0 1rem;
    }

    .logo{
        margin: .75rem 0 1rem .5rem;
    }

    .header-button{
        margin: 1.5rem 1.5rem 1.5rem 0;
    }
}


@media screen and (max-width:395px){
    .header-menu{
        background:linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
        border: none;
        outline: none;
        border-radius: 8px;
        font-weight: 500;
        font-size: .75rem;
        color: black;
        font-family: 'Readex Pro';
        font-style: normal;
        text-align: center;
        height:2rem;
        padding: 0 1rem;
    }

    .logo{
        margin: 0 0 0 1rem;
    }

    .header-button{
        margin: 0 1rem 0 0;
    }
}

@media screen and (max-width:320px){
    .header-menu{
        font-size: .7rem;
        height:2rem;
        padding: 0 1rem;
    }

    .logo{
        margin: 0 0 0 1rem;
    }

    .header-button{
        margin: 0 1rem 0 0;
    }
}