.connect-wallet-container{
    background: #252524;
    border-radius: 7px;
    height: auto;
    width: 19.8rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
}


.connect-wallet-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.conatiner-wallet-name{
    width: 100%;
    cursor: pointer;
}

@media screen and (min-width:769px) {
    .connect-wallet-container{
        width: 360px;
    }
    .connect-wallet-heading{
        font-size: 1.75rem;
    }
}

@media screen and (max-width:360px) {
    .connect-wallet-container{
        background: #252524;
        border-radius: 7px;
        height: auto;
        width: 16rem;
    }
}

@media screen and (max-width:320px) {
    .connect-wallet-container{
        background: #252524;
        border-radius: 7px;
        height: auto;
        width: 14rem;
    }
}