.hero-section{
    height: calc(100vh) !important;
    overflow: hidden;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
}

.image-div{
    height: 100vmin;
    width: 100vw;
    position: absolute;
    z-index: 1;
    mix-blend-mode: multiply;
    top: 0;
}

.bg-image{
    height: 100vmin;
    width: 100vw;
    position: absolute;
    z-index: 1;
    mix-blend-mode: multiply;
    top: 0;
    background-repeat: no-repeat;
}



