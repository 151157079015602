.coin-container{
    background: #14170D;
    border-radius: 1rem;
    /* width:100%; */
    padding: 1.2rem 1.75rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:0
}

.coin-container-right{
    background: #292928;
    border-radius: 6px;
    display: flex;
    padding: 0.6rem 1.2rem;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    gap: 0.5rem;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 48px !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutlineq,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline
{
    border-color: #ffffff00 !important;
    border-width: 2px !important;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    background-color: #292928 !important;
}

.css-qiwgdb,
.css-qiwgdb.css-qiwgdb.css-qiwgdb,
.css-fvipm8 {
    padding: 0 !important;
}
/* .coin-container-left{
    /* margin-left: 1rem;
} */

.coin-container-left input{
    background-color: transparent;
    border: none;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 1.75rem;
    padding: 1.2rem 0 !important;
    width: 100%;
    /* color: #666; */
    /* line-height: 141.8%; */
    color: #FFFFFF;
    opacity: 0.5;
    outline: none;
}

.coin-container-right img{
    width: 1.75rem;
    height: 1.75rem;
    margin: 0 !important;
}

.coin-container-right-name{
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
}

.css-6hp17o-MuiList-root-MuiMenu-list
{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important ;
    flex-direction: column !important;
    background-color: #20221D !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    padding: 0 6px 0 0  !important;
}

@media screen and (max-width: 769px){
    .coin-container-left input{
        font-size: 1.5rem;
    }
    .coin-container{
        padding: 1.2rem 1.2rem;
    }
    .coin-container-right img{
        width: 1.5rem;
        height: 1.5rem;
    }
    .coin-container-right-name{
        font-size: 1.1rem;
    }
}

/* 
@media screen and (max-width:2560px) {
    .coin-container{
        padding: 2.8em;
        width: 90%;
        margin-bottom: 1.25rem;
    }
    .coin-container-left input{
        font-size: 2.8rem;
    }

    .coin-container-right-name{
        font-size: 3rem;
    }

    .coin-container-right-image img{
        width: 3rem;
        height: 3rem;
    }

}

@media screen and (max-width:2360px) {
    .coin-container-left{
        width: 70%;
    }
    .coin-container-left input{
        width: 100%;
    }
}

@media screen and (max-width:1960px){
    .coin-container{
        padding: 2.2rem;
    }
    .coin-container-left input{
        font-size: 2rem;
    }

    .coin-container-right-name{
        font-size: 2.2rem;
    }

    .coin-container-right-image img{
        width: 2.2rem;
        height: 2.2rem;
    }
}

@media screen and (max-width:1440px){
    .coin-container{
        background: #14170D;
        border-radius: 1rem;
        width:90%;
        padding: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin:0
    }
    
    .coin-container-right{
        background: #292928;
        border-radius: .25rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: .5rem .75rem;
        color: white;
    }
    
    .coin-container-left{
        margin-left: 1rem;
    }
    
    .coin-container-left input{
        background-color: transparent;
        border: none;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.5;
        outline: none;
    }
    
    
    .coin-container-right-image img{
        width: 1rem;
        height: 1rem;
    }
    
    .coin-container-right-image{
        flex: .5;
    }
    
    .coin-container-right-name{
        flex: 1;
        margin-left: .2rem;
        margin-bottom: .2rem;
        font-weight: 600;
        font-size: 1rem;
    }
}

@media screen and (max-width:1120px) {
    .coin-container{
        padding: 1.3em;
    }
    .coin-container-left{
        width: 70%;
    }
}

@media screen and (max-width:960px) {
    .coin-container{
        padding: 1.2rem;
    }
    .coin-container-left{
        width: 60%;
    }
}

@media screen and (max-width:850px){
    .coin-container{
        padding: 1rem;
    }

    .coin-container-right{
        padding: .35rem .75rem;
    }
}

@media screen and (max-width:500px){
    .coin-container{
        background: #14170D;
        border-radius: 1rem;
        width: 93%;
        padding: 1.2rem .8rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 0;
    }

    .coin-container-left input{
        background-color: transparent;
        border: none;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.5;
        outline: none;
        width: 5rem;
    }

    .coin-container-right{
        margin-right: .5rem;
    }
}

@media screen and (max-width:360px) {
    .coin-container-right{
        padding: .25rem .5rem;
    }
    .coin-container-right-name{
        margin-left: 0;
    }
}

@media screen and (max-width:320px) {
    .coin-container-right-image img{
        width: .85rem;
        height: .85rem;
    }

    .coin-container-right-name{
        font-size: .8rem;
    }
} */