.home-header{
    width: 100%;
    height: 5rem;
    left: 0px;
    top: 0px;
    background: 
    rgba(20, 23, 13, 1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.home-logo{
    margin: 0 0 0rem 6.75rem;
}

.home-header-button{
    display: flex;
    gap: 1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 7.5rem 1.5rem 0;
}
.home-header-logo{
    height: 2.5rem !important;
    width: 140px !important;
    object-fit: cover !important;
}

.swap-link,.stake-link{
    background-color: transparent;
    border: none;
    outline: none;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 25px;
    text-transform: capitalize;
    color: #FDFCFF;
    opacity: 0.9;
    margin-right: 3rem;
    cursor: pointer;
}
.footer-find-us-logos.head{
    gap: 1rem !important;
}
.footer-find-us-logos.head a{
    margin: 0;
}

.quillhash-audit{
    height: 2rem;
    width: fit-content;
}

.home-header-menu{
    background:linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
    border: none;
    outline: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    color: black;
    font-family: 'Readex Pro';
    font-style: normal;
    text-align: center;
    height:2.3rem;
    padding: 0 1rem;
    cursor: pointer;
}
.home-header-menu-o{
    background-color: transparent;
    border: 1px solid rgba(250, 211, 122, 1);
    outline: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    color: #fff;
    font-family: 'Readex Pro';
    font-style: normal;
    text-align: center;
    height:2.3rem;
    padding: 0 1rem;
    cursor: pointer;
}

@media screen and (max-width:1050px) {
    .home-logo{
        margin: .75rem 0 1rem 4.5rem;
    }
    
    .home-header-button{
        margin: 1.5rem 4.5rem 1.5rem 0;
    }
}

@media screen and (max-width:850px){

    .home-logo{
        margin: 0 0 0 1.75rem;
    }

    .home-header-button{
        margin: 1.5rem 2.5rem 1.5rem 0;
    }
}

@media screen and (max-width:500px){
    .home-header-menu{
        font-size: .75rem;
        height:2rem;
        padding: 0 1rem;
    }

    .home-logo{
        margin: .75rem 0 1rem .5rem;
    }

    .home-header-button{
        margin: 1.5rem 1.5rem 1.5rem 0;
    }
}


@media screen and (max-width:395px){
    .home-header-menu{
        font-size: .75rem;
        height:2rem;
        padding: 0 1rem;
    }

    .home-logo{
        margin: 0 0 0 1rem;
    }

    .home-header-button{
        margin: 0 1rem 0 0;
    }
}

@media screen and (max-width:320px){
    .home-header-menu{
        font-size: .7rem;
        height:2rem;
        padding: 0 1rem;
    }

    .home-logo{
        margin: 0 0 0 1rem;
    }

    .home-header-button{
        margin: 0 1rem 0 0;
    }
}