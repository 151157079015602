.switch {
    position: relative;
    display: inline-block;
    width: 1.5rem;
    height: 1rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #828282;
    opacity: 0.5;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: .75rem;
    width: .75rem;
    left: 2px;
    bottom: 2px;
    background-color: black;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: green;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px green;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(.50rem);
    -ms-transform: translateX(.50rem);
    transform: translateX(.50rem);
  }
  
  .slider.round {
    border-radius: 1rem;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }