.road-map {
  position: sticky;
  top: 0;
  bottom: 0;
  padding-top: 24px;
  height: 100vh;
  font-weight: 400;
  max-width: 1290px;
  width: 90%;
  margin: 0 auto;
  background-color: #14170d;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.road-map-modal {
  width: 100%;
  max-width: 840px;
}
.road-map-heading {
  color: #fff;
  text-align: center;
  line-height: 1;
  font-weight: 700 !important;
  font-size: var(--font-size-large);
  font-family: var(--font-fam-dm);
}

.road-map-phases {
  margin-top: 85px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  font-family: var(--font-fam-out);
  color: #fff;
  position:  relative !important;
}

.road-map-phase {
  position: relative;
  width: 100%;
  padding: 24px;
  padding-left: 48px;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
}
.road-map-phase p, .road-map-phase h1 {
  margin: 0;
}
.road-map-phase-info.current{
  margin-top: 1rem;
}
.road-map-phase::before {
  content: "";
  position: absolute;
  height: 80%;
  width: 4px;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--color-golden);
}
.road-map-phase-heading {
  font-size: var(--font-size-med);
  font-weight: 400 !important;
  margin-bottom: 20px;
}
.road-map-phase-info {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.6);
  font-size: var(--font-size-small);
  height: 0;
  max-height: fit-content;
  overflow: hidden;
  transition: height 0.2s ease-out;
}

.road-map-phase.current {
  background-color: #20221d;
  border-radius: 10px;

}

.road-map-phase.current > .road-map-phase-info {
  min-height: 136px;
  height: fit-content;
}

.background-particle {
  position: absolute;
  width: 100px;
  height: 100px;
}

/* Background */
.road-map > .background-particle:nth-of-type(1) {
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.road-map > .background-particle:nth-of-type(2) {
  right: 10%;
  top: 2%;
}
.road-map > .background-particle:nth-of-type(3) {
  right: 3%;
  bottom: 3%;
}
.road-map > .background-particle:nth-of-type(4) {
  left: 3%;
  bottom: -30%;
}
.road-map > .background-particle:nth-of-type(5) {
  right: 5%;
  bottom: 5%;
  transform: rotateZ(15deg);
}

.road-map > .background-particle:nth-of-type(6) {
  left: 12%;
  top: 30%;
  z-index: 1;
}

.road-map > .background-particle:nth-of-type(7) {
  left: -5%;
  top: 2%;
  transform: rotateZ(15deg) scale(1.2);
}

.road-map > .background-particle:nth-of-type(8) {
  right: 12%;
  top: 18%;
  transform: rotateZ(15deg) scale(1.1);
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .road-map > .background-particle {
    display: none;
  }
  .road-map-heading {
    text-align: left;
  }
  .road-map-phases {
    /* width: 80%; */
    margin: 0 auto;
    margin-top: 1.4rem;
  }
  .road-map-phase-heading {
    font-size: 20px;
  }
  .road-map-phase-info {
    font-size: 16px;
    line-height: 141.8%;
  }
}
