.wallet-name-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(132, 132, 132, 0.2);
    border-radius: 4px;
    width: 95%;
    margin:1rem 0;
    padding: .5rem;
}

.wallet-container-left{
    flex: 0.5;
}

.wallet-name-container img{
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
    margin-left: .2rem;
}

.wallet-container-right{
    flex: 1;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 141.8%;
    color: #FFFFFF;
}

@media screen and (min-width:769px) {
    .wallet-container-right{
        font-size: 1.25rem;
    }

    .wallet-name-container img{
        height: 1.75rem;
        width: 1.75rem;
    }
}


