.mint-box-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 300px;
    background: #20221D;
    border-radius: 20px;
    padding: 1rem;
}

.mint-box-top{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mint-box-top-left{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 141.8%;
    color: #FFFFFF;
}

.mint-box-top-right{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 141.8%;
    text-align: right;
    color: #FFFFFF;
}

.mint-box-middle-box1,.mint-box-middle-box2{
    width: 100%;
    height: 60px;
    background: #14170D;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.mint-box-button{
    width: 100%;
}

input[type="text"],
input[type="number"]{
    background-color: transparent;
    border: none;
    outline: none;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 141.8%;
    color: #FFFFFF;
    opacity: 0.5;
}

.counter-box{
    background: #292928;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 141.8%;
    color: #FFFFFF;
    width: 50px;
    padding: .3rem .5rem;
    cursor: pointer;
}

.converted-value{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 141.8%;
    color: #FFFFFF;
}

.mint-button{
    width: 100%;
    align-items: center;
    padding: .75rem;
    background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 51.56%, #D1A94E 100%);
    border-radius: 12px;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 141.8%;
    text-align: center;
    color: #000000;
    outline: none;
    border: none;
    cursor: pointer;
}

@media screen and (max-width:1000px) {
    .mint-box-container{
        width: 320px;
    }

    input[type="text"],
    input[type="number"]{
        width: 60%;
    }
}

@media screen and (max-width:768px){
    .mint-box-container{
        width: 100%;
    }
}

@media screen and (max-width:500px) {
    input[type="text"],
    input[type="number"]{
        width: 50%;
        font-size: 18px;
    }
}

@media screen and (max-width:360px){
    .mint-box-top-left{
        font-size: 22px;
    }

    .mint-box-top-right{
        font-size: 12px;
    }

    .counter-box,.converted-value{
        font-size: 14px;
    }

    input[type="text"],
    input[type="number"]{
        font-size: 14px;
    }
}