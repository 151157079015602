.reward-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem .75rem;
    width: 25rem;
    height: 8rem;
    background: #20221D;
    border-radius: 8px;
}

.reward-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 141.8%;
    color: #FFFFFF;
}

.amount p{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 141.8%;
    color: #FFFFFF;
}

.amount p span{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: .85rem;
    line-height: 141.8%;
    color: #FFFFFF;
}

.b15{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.claim{
    background-color: transparent;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: .75rem;
    line-height: 141.8%;
    text-align: center;
    text-transform: capitalize;
    color: #F9D278;
    padding: .5rem 1rem;
    border: 1px solid #F9D278;
    border-radius: 8px;
    width: 12rem;
    margin-right: .75rem;
}

.token{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: .75rem;
    line-height: 141.8%;
    text-align: center;
    color: #14170D;
    background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 16.83%, #FFE099 67.68%, #B8933D 100%);
    box-shadow: 0px 0px 10px rgba(252, 217, 135, 0.4);
    border-radius: 8px;
    border: none;
    padding: .5rem 1rem;
    width: 12rem;
}

@media screen and (max-width:1150px){
    .reward-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem .75rem;
        width: 20rem;
        height: 8rem;
        background: #20221D;
        border-radius: 8px;
    }

    .token{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .75rem;
        line-height: 141.8%;
        text-align: center;
        color: #14170D;
        background: linear-gradient(91.52deg, #F9D278 0%, #FFE099 16.83%, #FFE099 67.68%, #B8933D 100%);
        box-shadow: 0px 0px 10px rgba(252, 217, 135, 0.4);
        border-radius: 8px;
        border: none;
        padding: .5rem 1rem;
        width: 9.5rem;
    }

    .claim{
        background-color: transparent;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .75rem;
        line-height: 141.8%;
        text-align: center;
        text-transform: capitalize;
        color: #F9D278;
        padding: .5rem 1rem;
        border: 1px solid #F9D278;
        border-radius: 8px;
        width: 9.5rem;
        margin-right: .75rem;
    }
}

@media screen and (max-width:850px){
    .reward-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem .75rem;
        width: 94.5%;
        height: auto;
        background: #20221D;
        border-radius: 8px;
    }

    .reward-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 141.8%;
        color: #FFFFFF;
    }

    .amount p{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 1.35rem;
        line-height: 141.8%;
        color: #FFFFFF;
    }

    .amount p span{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 400;
        font-size: .9rem;
        line-height: 141.8%;
        color: #FFFFFF;
    }

    .b15{
        width: 100%;
    }

    .b-claim{
        width: 100%;
        margin: 0 1rem 0 0;
    }

    .b-token{
        width: 100%;
    }

    .token{
        font-size: 1rem;
        border-radius: 8px;
        padding: .5rem 1rem;
        width: 100%;
    }

    .claim{
        font-size: 1rem;
        border-radius: 8px;
        width: 100%;
    }
}

@media screen and (max-width:500px){
    .reward-container{
        padding: 1rem .75rem;
        height: 8rem;
        background: #20221D;
        border-radius: 8px;
    }

    .reward-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 141.8%;
        color: #FFFFFF;
    }
    
    .amount p{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 141.8%;
        color: #FFFFFF;
    }
    
    .amount p span{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 400;
        font-size: .85rem;
        line-height: 141.8%;
        color: #FFFFFF;
    }

    .token{
        font-size: .75rem;
        border-radius: 8px;
        padding: .5rem 1rem;
    }

    .claim{
        font-size: .75rem;
        border-radius: 8px;
    }
}

@media screen and (max-width:395px){
    .reward-container{
        padding: 1rem 1rem;
        width: 90%;
        height: 7.5rem;
        background: #20221D;
        border-radius: 8px;
    }

    .token{
        font-size: .65rem;
        border-radius: 8px;
        padding: .5rem 1rem;
    }

    .claim{
        font-size: .65rem;
        border-radius: 8px;
    }
}

@media screen and (max-width:320px){
    .reward-container{
        padding: 1rem 1rem;
        width: 87%;
        height: 7.5rem;
        background: #20221D;
        border-radius: 8px;
    }

    .token{
        font-size: .55rem;
        border-radius: 8px;
        padding: .5rem 1rem;
    }

    .claim{
        font-size: .55rem;
        border-radius: 8px;
    }
}