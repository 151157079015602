.landing-page-container {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(20, 23, 13, 0.99);
  justify-content: space-between;
  position: relative;
  /* overflow-y: scroll; */
}

.landing-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
  margin-top: 4rem;
}

.landing-page-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.landing-page-top-heading {
  font-family: "Chivo";
  color: #ffffff;
  font-weight: 700;
}

.landing-page-top-content {
  font-family: "Readex Pro";
  color: #ffffff;
  opacity: 0.8;
  margin: 0.5rem 1.5rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.landing-page-top-content p {
  margin: 0;
  padding: 0;
  text-align: center;
  max-width: 800px;
}

.landing-page-top-buttons {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  margin: 3rem 1.5rem 6rem;
  /* width: 37%; */
}

button img {
  margin: 0;
}

.page-head {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page-top-heading {
  text-align: center;
}

#qr {
  height: 2rem;
  width: 2rem;
  /* padding-right: 0.5rem; */
}

.center-logo-pg {
  height: 400px;
  object-fit: cover;
  width: 60%;
  z-index: 1;
}

#center-logo {
  height: 100%;
  width: 100%;
  filter: drop-shadow(0px -10px 140px #ffe09933);
  /* position: absolute; */
  object-fit: cover;
  object-position: top center;
  z-index: 1;
  overflow: hidden;
}

.overlap-div {
  width: 100%;
  /* height: 400px; */
  position: relative;
  background-color: rgba(19, 22, 12, 1);
  /* transform: translateY(-285px); */
  padding-top: 0;
}

.h1-video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 3rem 0 ; */
}

.h1-video-background {
  width: 100%;
  height: 120%;
  padding: 8rem 4rem;
  /* backdrop-filter: blur(100px);*/
  /* position: absolute; */
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../assets/video_background.webp");
  display: flex;
  justify-content: center;
  align-items: center;
}

.h1-video-container {
  width: 100%;
  max-width: 1200px;
  min-height: 300px;
  background: #14170d;
  border-radius: 10px;
  display: flex;
  gap: 4rem;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  /* flex-direction: row; */
  backdrop-filter: brightness(30%);
  text-align: center;
}

.h1-video-container>div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.h1-video-heading {
  font-family: "Chivo";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 141.8%;
  text-transform: capitalize;
  color: #ffffff;
}

.h1-video-subheading {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 141.8%;
  color: #ffffff;
  opacity: 0.8;
  width: 100%;
}

.main-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 5rem;
  background-image: url(../../assets/mainfeaturesbg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-features-heading {
  font-family: "Chivo";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 141.8%;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
}

.main-features-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  justify-items: center;
}

.how-to-buy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400vh;
  padding: 8rem 4rem;
  position: relative;
  background-image: url(../../assets/coinsbg.png);
  background-position: center;
  background-size: contain;
  background-repeat: repeat-y;
}

.how-to-buy .container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  bottom: 0;
  justify-content: space-between;
  /* align-items: center; */
  margin: 0 4rem;
  gap: 6rem;
  /* overflow-y: scroll; */
}

.how-to-buy .container .title {
  font-family: "Chivo";
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 141.8%;
  color: #ffffff;
}

.how-to-buy .container .btn {
  margin-top: 2rem;
}

.mobileVis {
  display: none !important;
}

.how-to-buy .container .setps-to-buy {
  height: 100vh;
  /* position: sticky; */
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.how-to-buy .container .steps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  font-family: "Readex Pro";
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
}

.how-to-buy .container .steps .step {
  padding: 1rem;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s ease-in !important;
}

.how-to-buy .container .steps .step-active {
  background-color: #20221d;
  border-radius: 8px;
}

.how-to-buy .container .steps .sub-step {
  display: none;
  font-size: 1rem;
  font-weight: 300;
  margin-top: 1rem;
  opacity: 0.7;
  transition: display 0.3s ease-in !important;
}

.how-to-buy .container .steps .sub-step-active {
  display: block;
}

.how-to-buy .img-box {
  height: 100vh;
  width: 500px;
  min-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
}

.how-to-buy .img-box img {
  width: 80%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0px -10px 100px #ffe09922);
}

.reward-allocation {
  /* padding-bottom: 6rem; */
  padding: 0 1.5rem 6rem;
  margin: 0 1.5rem;
}

.reward-allocation .swiper {
  height: fit-content;
}

.reward-allocation .reward-swiper-row {
  /* height: fit-content; */
  height: 100px;
}

.reward-allocation .button-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-allocation .button-box img {
  height: 1rem;
  width: 1rem;
}

.reward-allocation .body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.reward-allocation .body .img-box {
  height: 600px;
  width: 600px;
  position: relative;
}

.reward-allocation .body .img-box img {
  width: 100%;
  height: 100%;
  color: #fff !important;
  object-fit: contain;
}

.reward-allocation .body .rewards {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 2.5rem;
  /* height: 100%; */
}

.reward-allocation .body .rewardsRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* flex-direction: column; */
  gap: 2.5rem;
  /* height: 100%; */
}

.rewardsRow .reward {
  position: relative;
  color: #fff;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.img-box-1-left,
.img-box-1-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-box-1-left {
  margin-left: 1.5rem;
}

.rewards .img-box-1:last-child {
  transform: rotate(180deg);
}

.img-box-1-right {
  margin-right: 1.5rem;
}

.img-box-1-right img {
  transform: rotate(180deg);
}

.rewardsRow .reward span:first-child {
  font-weight: 300;
}

.rewardsRow .reward span:last-child {
  font-size: 1.5rem;
  font-weight: 400;
}

.rewardsRow .reward::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 4px;
  border-radius: 9999px;
  /* background-color: red; */
}

.rewardsRow .reward-1::before {
  background-color: #9a7d59;
}

.rewardsRow .reward-2::before {
  background-color: #2a2c38;
}

.rewardsRow .reward-3::before {
  background-color: #474755;
}

.rewardsRow .reward-4::before {
  background-color: #474755;
}

.rewards .reward {
  position: relative;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.rewards .img-box-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rewards .reward span:first-child {
  font-weight: 300;
}

.rewards .reward span:last-child {
  font-size: 1.5rem;
  font-weight: 400;
}

.rewards .reward::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 4px;
  border-radius: 9999px;
  /* background-color: red; */
}

.rewards .reward-1::before {
  background-color: #9a7d59;
}

.rewards .reward-2::before {
  background-color: #2a2c38;
}

.rewards .reward-3::before {
  background-color: #474755;
}

.rewards .reward-4::before {
  background-color: #474755;
}

.bg-ellipse {
  position: absolute;
  top: 0;
  left: -10%;
}

.staking {
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.staking .img-box {
  width: 50%;
  height: 100%;
}

.staking .img-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.staking .staking-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  padding: 0 4rem;
  width: 50%;
}

.heading .btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.nft-collection {
  padding: 8rem 0;
  width: 100%;
  position: relative;
}

.nft-collection .nfts .card-box {
  height: 300px;
  width: 200%;
  position: relative;
  overflow: visible;
}

.nft-collection .nfts .card-box-1 {
  margin-top: 1rem;
  overflow: visible;
}

.nft-collection .nfts .card-box img {
  position: absolute;
  /* left: -30%; */
  /* object-position: center; */
  width: fit-content;
  height: 100%;
  object-fit: contain;
}

.team-members {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  position: relative;
}

.team-members {
  font-family: "Readex Pro";
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  justify-items: center;
  color: #fff;
}

.team-members>div {
  width: 200px;
  height: 260px;
  background-color: #20221d;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.team-members>div .img-box {
  width: 200px;
  height: 200px;
  margin-bottom: 4px;
}

.team-members .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 !important;
}

.team-members>div>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team-members>div>div span:first-child {
  font-size: 1rem;
}

.team-members>div>div span:last-child {
  font-weight: 300;
  font-size: 0.8rem;
}

.join-us {
  margin-bottom: 3rem;
}

.faq {
  color: #fff;
  display: flex;
  gap: 8rem;
  padding: 12rem 1.5rem;
}

.faq .head {
  font-family: "Chivo";
  font-style: normal;
  font-weight: 700;
  font-size: 66px;
}

.faq .body {
  display: flex;
  font-family: "Readex Pro";
  flex-direction: column;
  gap: 1rem;
}

.faq .faq-box {
  max-width: 600px;
  width: 600px;
  padding: 1.5rem;
  background-color: #20221d;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.faq .question {
  font-size: var(--font-size-med);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  font-weight: 400;
  cursor: pointer;
}

.faq .answer {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 1rem;
}

.faq img {
  height: 1rem;
  width: 1rem;
}

.join-community-footer {
  background: url(../../assets/joincommunity.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-direction: column;
}

.join-community-footer .landing-page-top-buttons {
  margin: 2rem 1.5rem 6rem;
}

.roadmap {
  width: 100%;
  height: 400vh;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.btns-f {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.home-header-logo.pc,
.header-logo.pc {
  display: block;
}

.home-header-logo.mobile,
.header-logo.mobile {
  display: none;
}

@media screen and (max-width: 768px) {

  img.home-header-logo,
  img.header-logo {
    height: 2.5rem !important;
    width: 3rem !important;
    object-fit: cover;
    object-position: left;
  }

  .home-header-logo.pc,
  .header-logo.pc {
    display: none;
  }

  .home-header-logo.mobile,
  .header-logo.mobile {
    display: block;
  }

  .landing-page-top-heading {
    font-size: 56px;
  }

  .roadmap {
    height: fit-content;
  }

  .landing-page-top-content p {
    font-size: 1.2rem;
    padding: 0 1rem;
  }

  .landing-page-top-buttons {
    gap: 0.4rem;
    /* align-items: center; */
  }

  .landing-page-top-buttons button {
    font-size: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  /* .landing-page-top-buttons.footer{
    flex-direction: column;
    gap: 1rem;
  } */
  .center-logo-pg {
    display: contents;
    width: 130%;
  }

  .h1-video-heading {
    font-size: 56px;
  }

  .main-features-heading {
    font-size: 46px;
  }

  .main-features-content {
    grid-template-columns: repeat(1, 1fr);
    gap: 6rem;
  }

  .mobileVis {
    display: flex !important;
  }

  .mobileHid {
    display: none !important;
  }

  .how-to-buy {
    padding-top: 0;
    padding-bottom: 3rem;
    height: fit-content;
  }

  .how-to-buy .container {
    flex-direction: column-reverse;
    gap: 0;
    /* top: -50%; */
    /* bottom: 0; */
  }

  .how-to-buy .img-box {
    height: fit-content;
    padding: 0 !important;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .how-to-buy .img-box img {
    width: 80%;
    margin: 0;
  }

  .how-to-buy .container .setps-to-buy {
    padding: 1.5rem;
  }

  .how-to-buy .container .steps .step {
    width: auto;
  }

  .reward-allocation {
    width: 100%;
  }

  .reward-allocation .button-box {
    margin-top: 2rem;
  }

  .reward-allocation .heading {
    width: 100%;
    position: relative;
  }

  .reward-allocation .body {
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  .reward-allocation .body .img-box {
    width: 100%;
    height: fit-content;
    margin-top: 2rem;
  }

  .staking {
    flex-direction: column;
    /* padding-bottom: 4rem; */
    height: fit-content;
    position: relative;
    width: 100%;
  }

  .staking .img-box {
    width: 100%;
  }

  .staking .staking-body {
    width: 80%;
    padding: 1.5rem;
  }

  .nft-collection {
    width: 100%;
  }

  .nft-collection .landing-page-top-heading {
    font-size: 46px;
  }

  .team-members {
    margin-top: 2rem;
  }

  .team-members>div {
    width: 160px;
    height: 220px;
    background-color: #20221d;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }

  .team-members>div .img-box {
    width: 160px;
    height: 160px;
    margin-bottom: 4px;
  }

  .nft-collection .nfts .card-box img {
    position: absolute;
    left: -30%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .page-head {
    height: 550px;
  }

  .img-box-1-right img {
    transform: rotate(90deg);
  }

  .img-box-1-left img {
    transform: rotate(-90deg);
  }
}

@media screen and (max-width: 1024px) {
  .h1-video-container {
    flex-direction: column;
    padding: 0 1.5rem;
  }

  .h1-video-container>div {
    width: 100%;
  }

  .h1-video-background {
    padding: 4.5rem 1.5rem;
  }

  .team-members {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .faq {
    /* width: 100%; */
    flex-direction: column;
    position: relative;
    gap: 0;
    padding: 4rem 1.5rem;
  }

  .faq .body {
    padding: 1.5rem;
    font-family: "Readex Pro";
    /* width: 100%; */
  }

  .faq .faq-box {
    max-width: auto;
    width: auto;
  }

  .faq .head {
    padding: 1.5rem;
  }
}

@keyframes moverRL {
  0% {
    transform: translateX(-100px);
  }

  50% {
    transform: translateX(100px);
  }

  100% {
    transform: translateX(-100px);
  }
}

@keyframes moverLR {
  0% {
    transform: translateX(100px);
  }

  50% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateX(100px);
  }
}

/* @media (prefers-reduced-motion: no-preference) {
  #nft-card-1 {
    animation: moverRL 8s infinite;
  }
  #nft-card-2 {
    animation: moverLR 8s infinite;
  }
} */