.graph-container{
    width: 22.25rem;
    /* height: 13.5rem; */
    height:100%;
    background: #20221D;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.area-graph {
    padding: 0 10px 10px 10px;
}
.graph-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 141.8%;
    color: #FFFFFF;
    padding: 1rem 1rem 0 1rem;

}

@media screen and (max-width:1150px){
    .graph-container{
        width: 25rem;
        height: 12.5rem;
        background: #20221D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        margin-bottom: .75rem;
    }
}

@media screen and (max-width:850px){
    .graph-container{
        width: 94%;
        height: 100%;
        background: #20221D;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        margin-bottom: .75rem;
    }
}

@media screen and (max-width:500px) {
    .graph-container{
        width: 92.5%;
    }
}

@media screen and (max-width:395px) {
    .graph-container{
        width: 90%;
    }
}

@media screen and (max-width:320px) {
    .graph-container{
        width: 87%;
    }
}