.generic-container{
    width: 15rem;
    height: 13rem;
    background: #20221D;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.25rem 0 1.25rem;
}

.generic-container-heading{
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 141.8%;
    color: #FFFFFF;
}

.generic-container-content{
    display: flex;
    flex-direction: column;
}

.total, .staker, .rewards, .apy{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: .8rem;
    line-height: 141.8%;
    color: #FFFFFF;
    opacity: 0.8;
}

@media screen and (max-width:1150px) {
    .generic-container{
        width: 25rem;
        height: 14rem;
        background: #20221D;
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem 0 1rem;
    }

    .total, .staker, .rewards, .apy{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .9rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.8;
    }

    .generic-container-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 141.8%;
        color: #FFFFFF;
    }
}

@media screen and (max-width:850px) {
    .generic-container{
        width: 94%;
        height: 14rem;
        background: #20221D;
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        padding: 1rem 1rem 0 1rem;
    }

    .total, .staker, .rewards, .apy{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .9rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.8;
    }

    .generic-container-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 141.8%;
        color: #FFFFFF;
    }
}

@media screen and (max-width:500px) {
    .generic-container{
        width: 93%;
    }

    .total, .staker, .rewards, .apy{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .9rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.8;
    }

    .generic-container-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 141.8%;
        color: #FFFFFF;
    }
}

@media screen and (max-width:395px) {
    .generic-container{
        width: 90%;
        height: auto;
    }

    .total, .staker, .rewards, .apy{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: .7rem;
        line-height: 141.8%;
        color: #FFFFFF;
        opacity: 0.8;
    }

    .generic-container-heading{
        font-family: 'Readex Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 141.8%;
        color: #FFFFFF;
    }
}

@media screen and (max-width:320px) {
    .generic-container{
        width: 87%;
    }
}